<template>
  <div class="manage-lawyer">
    <div class="container fluid">
      <h2 class="p-3 mb-1">Manage Lawyers</h2>
      <!-- ============================= Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 px-1 mb-2"
            label="Name"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
          <fd-input
            v-model="filter['email']"
            class="col-12 sm-col-6 px-1 mb-2"
            label="Official Email"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>
      <!-- ========================= Table Listing ========================= -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="lawyerTableColumns"
          :rows="lawyerTableData"
          :totalRows="lawyerTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="toLawyerDetail"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddLawyer">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Table Buttons -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <dropdown-button buttonClass="circle flat">
                <template #buttonContent>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <div class="card text-left">
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="toEditLawyer(props.row.id)"
                  >
                    <i class="fas fa-pen"></i>
                    <span class="ml-2">Edit Lawyer</span>
                  </fd-list-item>
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="
                      openToggleLawyerStatus(props.row.id, props.row.isActive)
                    "
                  >
                    <i class="fas fa-ban"></i>
                    <span class="ml-2">{{
                      props.row.isActive ? "Ban Lawyer" : "Unban Lawyer"
                    }}</span>
                  </fd-list-item>
                  <fd-list-item
                    class="cursor-pointer unselectable"
                    @click="deleteLawyer(props.row)"
                  >
                    <i class="fas fa-trash"></i>
                    <span class="ml-2">Delete Lawyer</span>
                  </fd-list-item>
                </div>
              </dropdown-button>
            </span>
            <!-- Active status -->
            <span
              v-else-if="props.column.field == 'isActive'"
              class="status d-flex align-items-center justify-content-center mr-1"
              :class="getStatusClass(props.row.isActive)"
            >
              <i
                class="fas"
                :class="{
                  'fa-check': props.row.isActive,
                  'fa-times': !props.row.isActive
                }"
              ></i>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { lawyer as lawyerAPI } from "@/api";

export default {
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      lawyerTableData: [],
      lawyerTablePagination: {},
      lawyerTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Active",
          field: "isActive",
          tdClass: "text-center",
          width: "100px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Contact No.",
          field: (row) => this.$getUserPhoneNumber(row.user)
        },
        {
          label: "Official Lawyer Email",
          field: "user.email"
        },
        {
          label: "Lawyer Firm",
          field: "lawyerFirmName"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      agencyOptions: [],
      branchOptions: [],
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        email: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getAllLawyers();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ page: params.currentPage });
      this.getAllLawyers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllLawyers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toAddLawyer() {
      this.$router.push({ name: "ManageLawyersAdd" });
    },
    toEditLawyer(id) {
      this.$router.push({
        name: "ManageLawyersEdit",
        params: { id: id }
      });
    },
    openToggleLawyerStatus(id, isActive) {
      // Set message
      let action;
      isActive ? (action = "ban") : (action = "unban");
      let message = `Are you sure you want to ${action} this user?`;

      this.$confirm({
        message: message,
        type: "alert"
      }).then((confirmed) => {
        if (confirmed) {
          this.confirmToggleStatus(id, !isActive);
        }
      });
    },
    async confirmToggleStatus(id, status) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.setLawyerStatus(id, status);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: `Successfully ${status ? "unban" : "ban"} the lawyer.`
        });

        await this.getAllLawyers();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to ${
            status ? "unban" : "ban"
          } the lawyer. Please try again later.`
        });
      }
    },
    toLawyerDetail(event) {
      let id = event.row.id;
      this.$router.push({
        name: "ManageLawyersDetail",
        params: {
          id: id
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.$store.commit("setIsLoading", true);

      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllLawyers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllLawyers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getAllLawyers() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await lawyerAPI.getAllLawyers({
          ...filteredParam,
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.lawyerTableData = this._.cloneDeep(data.data);
        this.lawyerTablePagination = this._.cloneDeep(data.meta.pagination);
        return;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch lawyers data. Please try again later."
        });
      }
    },
    async setLawyerStatus(id, isActive) {
      try {
        let response = await lawyerAPI.editActive(id, {
          isActive: isActive
        });
        return response;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Update Status",
          text: "Failed to update lawyer's status. Please try again later."
        });
      }
    },
    async deleteLawyer(lawyer) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Lawyer",
        message: `Are you sure you want to delete this lawyer (${lawyer.name})? This action cannot be undone.`,
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await lawyerAPI.deleteLawyer(lawyer.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "The lawyer has been deleted successfully."
          });

          await this.getAllLawyers();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the lawyer. Please try again later."
          });
        }
      }
    },
    // ======================== Miscellaneous =========================
    getStatusClass(status) {
      switch (status) {
        case true:
          return "active";
        case false:
          return "inactive";

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-lawyer {
  .status {
    padding: 10px 25px;
    border-radius: 5px;
    color: white;
    background: $color-success;
    &.active {
      background: $color-success;
    }
    &.inactive {
      background: $color-warn;
    }
  }
}
</style>
